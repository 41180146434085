import React from "react";
import { WhyYouNeedUs } from "./Landing";
import Nav from "./Nav";
import Footer from "./Footer";

export default function About() {
  // Why You Need Us Variable
  let desc, sub, img;
  desc = `نحن نعمل على صوت الحقيقة والتعامل مع جميع أنواع القضايا القانونية ، والتغلب على التحديات القانونية التي تواجه الأفراد والشركات في حياتهم العملية والشخصية ، وإلتزامنا بذلك يكون لإفادة العميل وإيصاله الى اعلى درجات الاستفادة من مهاراتنا وخبراتنا القانونية التي تتوافق مع حاجات عملائنا.`;
  sub = `خدماتنا عالية المستوى تساعدنا على تقديم الأفضل وتحقيق أهدافنا المتمثلة في جعلنا نموذجاً يحتذى به في تحقيق العدالة بنزاهة وإخلاص وشفافية. فخدماتنا المهنية في الغالب خدمات قانونية ذات جودة عالية، وترتقي وتتوج برضى عملائنا الكرام.`;
  img = "3.jpg";
  // End
  return (
    <div className="about-us">
      {<Nav />}
      {<WhyYouNeedUs title="من نحن" desc={desc} sub={desc} img={img} />}
      {<Contact />}
      {<Footer />}
    </div>
  );
}

export function Contact() {
  return (
    <>
      <div className="contact">
        <div className="container">
          <div className="row">
            <div className="text col-12 col-md-6">
              <div className="heading-1">
                <span className="d-block text-end">من اجلكم</span>
                <h4>تواصل معنا</h4>
              </div>
              <span className="my-3 d-block">
                تواصل معنا عبر الواتس آب على الرقم{" 966XXXXXX"}
              </span>
              <p>
                يمكنك التواصل معنا عن الطريق البريد الإكتروني و سيتم التواصل معك
                في اقرب وقت ممكن نرجو منك كتابة جميع التفاصيل
              </p>
              <p>
                سيتم تصفية الرسائل و دراسة الحالة بشكل جدي و دقيق . سنتعامل مع
                جميع الطلبات و الحالات القادمة من جميع انحاء المملكة بكل صدر رحب
                و ونسعى لتحقيق العدالة
              </p>
            </div>
            <form className="col-12 col-md-6 row">
              <div className="hide-box col-12 col-md-6 ">
                <input type="text" placeholder="الإسم" />
              </div>
              <div className="hide-box col-12 col-md-6 ">
                <input type="text" placeholder="البريد الإكتروني" />
              </div>
              <div className="hide-box col-12 col-md-6 ">
                <input type="text" placeholder="رقم الهاتف" />
              </div>
              <div className="hide-box col-12 col-md-6 ">
                <input type="text" placeholder="نوع المشكلة" />
              </div>
              <div className="hide-box">
                <textarea className="col-12" placeholder="الرسالة"></textarea>
              </div>
              <div className="hide-box d-block text-end ">
                <button className="button">حجز موعد</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
