import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Nav({ display }) {
  useEffect(() => {
    if (display !== "block") {
      nav.current.classList.add(...["bg-transparent", "position-relative"]);
    } else {
      nav.current.classList.remove(...["bg-transparent", "position-absolute"]);
      nav.current.classList.add("gray");
    }
  });

  // Ref
  let menu = useRef();
  let nav = useRef();

  let tell = () => {
    menu.current.classList.remove("d-none");
    menu.current.classList.add("partial");
  };
  let closeMenu = () => {
    menu.current.classList.add("d-none");
    menu.current.classList.remove("partial");
  };

  return (
    <nav ref={nav} className="w-100" id="nav">
      <div className="container d-flex align-items-center justify-content-between">
        <div className="image p-3">
          <img alt="logo" src={"images/bn.png"} style={{ width: "80px" }} />
        </div>
        <img
          className="burger d-sm-block d-md-none"
          alt="menu"
          src="images/menu-hamburger-white.svg"
          onClick={() => tell()}
        />
        <ul
          ref={menu}
          className="d-none d-md-flex flex-row-reverse align-items-center justify-content-center m-0 w-100 gap-5"
        >
          <span
            onClick={() => closeMenu()}
            className="fw-bold text-white d-md-none d-block my-2 mb-4 fs-1"
          >
            X
          </span>
          <h3 className="fs-3 fw-bold  d-md-none d-block">الصفحات</h3>
          <li className="px-1">
            <HashLink to="/#why-you-need-us" className="">
              لماذا تحتاج إلينا
            </HashLink>
          </li>
          <li className="px-1">
            {/* onClick{()=>navigate("order-summary")} */}
            <HashLink to="/#how-can-we-help-you" className="">
              كيف يمكننا مساعدتك
            </HashLink>
          </li>
          <li className="px-1">
            <HashLink to="/#testimonials" className="">
              آراء العملاء
            </HashLink>
          </li>
          <li className="px-1">
            <Link to="/about-us" className="">
              تواصل معنا
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
