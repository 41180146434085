import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="p-5">
        <div className="container">
          <div className="border border-1 my-5"></div>
          <h5> جميع الحقوق محفوظة | بن مساعد © 2023</h5>
        </div>
      </footer>
    </>
  );
}
