import logo from "./logo.svg";
import "./App.css";
import "./scss/main.css";
import Landing from "./components/Landing";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import About from "./components/About";
function App() {
  return (
    <div className="App">
      {/* <Landing /> */}
      {/*  */}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about-us" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;
