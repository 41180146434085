import React from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import { Contact } from "./About";

export default function Landing() {
  // Why You Need Us Variable
  let title, desc, sub, img;
  title = `لماذا تحتاج الينا  `;
  desc = `قضايا الأحوال الشخصية والأسرية من زواج وتثبيت زواج وطلاق وخلع ونفقة وحضانة أطفال وتوزيع وتقسيم الميراث وغيرها من مسائل الأسرة والتي تحتاج استشارة محامي. تفسير وتوضيح بعض القوانين والأنظمة السعودية التي تطلب منه تفسيرها.
  `;
  sub = `المشورة القانونية لمعالجة أي إشكاليات قانونية تعترضك وإضفاء صبغة نظامية على أعمالك. استشارات تقضي إلى الصلح والتوفيق في العديد من القضايا. إبرام اتفاقيات الصلح وعقد مجالس الصلح شرعية. المنازعات المنظورة لدى هيئات التحكيم والإعداد الكامل واعتماد وثيقة التحكيم.
  `;
  img = "lawerPerson.jpg";

  // End
  return (
    <>
      <Nav />
      <LandImage />
      <HowItWork />
      <WhyYouNeedUs title={title} desc={desc} sub={sub} img={img} />
      <HowCanWeHelpYou />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
}
function LandImage() {
  return (
    <div className="landing">
      <div className="title">
        <span className="fs-4">الشركة الأكثر موهبة</span>
        <h1 className="fs-1">نحن نقاتل من اجل عدالتك مثل صديقك.</h1>
        <button className="button">تواصل معنا الآن</button>
      </div>
    </div>
  );
}
function HowItWork() {
  let howWork = [
    { img: "lawyer.png", span: "احجز", title: "موعدك" },
    { img: "justice.png", span: "احصل على", title: "نصائح خبير مجانية" },
    { img: "users1.png", span: "يمكنك الإنضام بسهولة", title: "الى فريقنا" },
  ];

  return (
    <div className="how-it-work">
      <div className="container">
        <div className="row">
          {howWork.map(({ img, span, title }, index) => {
            return (
              <div
                key={index}
                className="hide-box p-3 col-12 col-md-6 col-lg-4"
              >
                <div className="box d-flex">
                  <div className="image">
                    <img
                      className="img-fluid"
                      src={"images/" + img}
                      alt="users"
                    />
                  </div>
                  <div className="text py-2 px-3">
                    <span className="fs-6 d-block">{span}</span>
                    <h4 className="fs-4 mt-1 text-white">{title}</h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export function WhyYouNeedUs({ title, desc, sub, img }) {
  return (
    <div className="why-you-need-us" id="why-you-need-us">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="image ">
              <img src={"images/" + img} className="img-fluid" alt="people" />
            </div>
          </div>
          <div className="expert col-12 col-lg-6 p-3">
            <div className="heading-1">
              <span>نحن محترفون</span>
              <h4>{title}</h4>
            </div>
            <p>{desc}</p>
            <p>{sub}</p>
            <button className="button">المزيد عنا</button>
          </div>
        </div>
      </div>
    </div>
  );
}
function HowCanWeHelpYou() {
  let howHelp = [
    {
      img: "family.png",
      title: "قانون العائلة",
      desc: "حقوق المرأة و الورثة و العنف الأسري",
    },
    {
      img: "health-insurance-symbol-of-a-man-with-broken-arm.png",
      title: "إصابة شخصية",
      desc: "الإعتداء الجسدي",
    },
    {
      img: "hacker.png",
      title: "قانون الجرائم",
      desc: "جرائم المخدرات و السرقة و المعلوماتية ",
    },
    {
      img: "graduation (1).png",
      title: "قانون التعليم",
      desc: "الإععتداء و الضرب و التظلم",
    },
    {
      img: "owner.png",
      title: "قانون الأعمال",
      desc: "الغرامات و إدارة المستهلك و البيئة",
    },
    {
      img: "home (3).png",
      title: "قانون العقارات",
      desc: "حدود الأراضي و الملكية العقارية و الإستثمار",
    },
  ];
  return (
    <div className="how-can-we-help-you" id="how-can-we-help-you">
      <div className="container">
        <div className="heading-2">
          <span>منطقة الممارسة</span>
          <h4>كيف يمكننا مساعدتك</h4>
        </div>
        <div className="row">
          {howHelp.map(({ img, title, desc }, index) => {
            return (
              <div
                key={index}
                className="hide-box col-12 col-md-6 col-lg-4 p-2"
              >
                <div className="box d-flex py-4 px-3 gap-3 align-items-center">
                  <div className="image">
                    <img src={"images/" + img} alt="user" />
                  </div>
                  <div className="text text-end">
                    <h5 className="title">{title}</h5>
                    <p className="desc">{desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
function OurResentCaseStudies() {
  return <div className="our-resent-case"></div>;
}
function Testimonials() {
  return (
    <div className="testimonials" id="testimonials">
      <div className="container">
        <div className="row">
          <div className="quotes col-12 col-lg-6 d-flex flex-column justify-content-center">
            <div className="heading-1 d-flex flex-column align-items-center">
              <span>ماذا قالو عنا</span>
              <h4 className="">آراء العملاء </h4>
            </div>
            <div className="quote">
              <img src="images/quote.png" alt="quote" />
              <p className="fs-5 mb-5">
                يتعامل مع القضايا بكل حزم ..عمله وانجازه رائع جدا .. يقوم باتخاذ
                كل مايلزم لأداء المهمة الموكلة إليه باذلا غاية جهده في حدود
                الشريعة والأنظمة المرعية . في أعماله المتميزة. سعدت بكوني عميل
                لديكم.
              </p>
              {/* <div className="lawer">
                <img src="" alt="avatar" />
                <div className="text">
                  <h4>مصطفى علي</h4>
                  <span>مستشار محامي</span>
                </div>
              </div> */}
            </div>
          </div>
          <div className="image col-12 col-lg-6">
            <img className="img-fluid" src="images/person.png" alt="person" />
          </div>
        </div>
      </div>
    </div>
  );
}
function FreeConsulting() {
  return <div>Landing</div>;
}
function QualifiedAttorneys() {
  return <div>Landing</div>;
}
function Statsitcs() {
  return <div>Landing</div>;
}
function LatestNews() {
  return <div>Landing</div>;
}
